import store from '@/store'
import { updateProperty } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export default {
  load (propertyId) {
    const property = store.getters['properties/getPropertyById'](propertyId)
    // Property data is already loaded by the layout, no need to call API again.
    const { niche, type, project, notary_of_owners, notary_of_buyers, syndic, ...payload } = { ...property }
    // We won't use notary_of_owners, notary_of_buyers, syndic, hence not including them below,
    // as they cannot be sent in the format they are received, back to the API.
    return { project: store.getters['properties/getProjectById'](project), type: type.id, niche: niche?.id, ...payload }
  },
  async submit (propertyId, data) {
    try {
      const payload = { ...data }
      if (payload.city?.id) payload.city = payload.city.id
      if (payload.street?.id) payload.street = payload.street.text
      if (payload.project?.id) payload.project = payload.project.id
      if (payload.is_immediately_available) payload.available_from = null
      if (payload.termination_reason?.id) payload.termination_reason = payload.termination_reason.id

      const response = await updateProperty(propertyId, payload)
      await Promise.all([
        store.dispatch('properties/loadProperty', propertyId),
        // Load project when updating it, as it is loaded once, for the whole layout.
        ...payload.project
          ? [store.dispatch('properties/loadProject', payload.project)]
          : []
      ])
      successModal('De wijzigingen zijn succesvol opgeslagen')
      return response
    } catch (error) {
      let errorText = ''

      if (error.response?.data?.code?.[0] === 'REFERENCE_NOT_UNIQUE') {
        errorText = 'De gewijzigde referentie is reeds in gebruik.'
      }

      errorModal(errorText || 'Er ging iets fout bij het wijzigen van het pand.')
      throw error
    }
  }
}
